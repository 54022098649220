import {
  defineStore,
  storeToRefs,
} from 'pinia';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { useAuthStore } from '~/stores/auth';
import type { NewsStateStore } from '~/types/stores/news';
import type { NewsItem } from '~/types/general/news';

export const useNewsStore = defineStore({
  id: 'news',
  state: (): NewsStateStore => ({
    newsData: [],
    newById: {},
  }),
  getters: {
    getNewsData: (state) => state.newsData,
    getNewById: (state) => state.newById,
  },
  actions: {
    async fetchNewsData() {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);

      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.news}/list`,
          { query: { lang: getUserData.value.locale } },
        );

        this.newsData = data as NewsItem[];
      } catch (error) {
        throw error;
      }
    },
    async fetchNewsDataForSSR() {
      const authStore = useAuthStore();
      const { getUserData } = storeToRefs(authStore);
      const url = `${useBaseAppUrl().api}${API_PREFIX.news}/list`;
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        const data = await $fetch(url, { query: { lang: getUserData.value.locale } });

        this.newsData = data as NewsItem[];
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
    async fetchNewById(id: number) {
      try {
        const { data } = (await useMyFetch().get(
          `${API_PREFIX.news}/news-detail`,
          { query: { newsId: id } },
        ));

        this.newById = data as NewsItem;
      } catch (error) {
        throw error;
      }
    },
    async fetchNewByIdForSSR(id: number) {
      const url = `${useBaseAppUrl().api}${API_PREFIX.news}/news-detail`;
      const startTime = Date.now();
      const { $ssrRequestLogger } = useNuxtApp();

      try {
        const data = await $fetch(url, { query: { newsId: id } });

        this.newById = data as NewsItem;
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: true,
            result: data,
          });
        }
      } catch (error) {
        if (import.meta.server) {
          $ssrRequestLogger({
            url,
            startTime,
            success: false,
            result: error,
          });
        }
      }
    },
  },
});
